jQuery(function($){
	$(document).ready(function() {

	/**
	 * Code fired on each page
	 *
	 *	@author Przemysław Hernik
	 *	@date 03.02.2017
	 *	@see https://toddmotto.com/mastering-the-module-pattern/
	 */

	 initJS = (function(){

	 	var

	 	init = function(){
	 		_clickableDropdownParent();
	 		_readMoreACF();
	 		_initMobileMenu();
	 		_initHrefScroll();
	 		_initSwiper();
	 		_initBackgroundMenu();
	 	},

	 	finalize = function(){

	 	},

	 	_readMoreACF = function(){
	 		$('p').html(function(_, text) {
	 			return text.replace('<!--more-->', '<a href="#" class="read_more">Więcej</a>' );
	 		});
	 	},

			/**
			 * Make dropdown parent clickable
			 *	By default BS4 blocks links which are dropdown parent.
			 *	@author Przemysław Hernik
			 */
			 _clickableDropdownParent = function(){
			 	$("a.dropdown-toggle").click(function(){
			 		window.location.href = $(this).attr('href');
			 	});
			 },


			/**
			* scrool to section on click
			* @author KT
			*/
			_initHrefScroll = function(){
	      $("a[href*='#']").click(function () {
	      	$('#overlay').removeClass('open');
					$('body').removeClass('menu-open');
	        target = this.hash;
	          $('html, body').animate({
	              scrollTop: $(target).offset().top - 60
	          }, 1000);
	          return false;
	      });
			},

			/**
			* inite swiper
			* @author KT
			*/
			_initSwiper = function(){
				var swiper = new Swiper('.swiper-container', {
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
					loop: true,
					speed: 1200,
					autoplayDisableOnInteraction: false,
					autoplay: {
						delay: 50000,
					},
				});
			};

			/**
			* Fixed navbar background color
			* @author KT
			*/
			_initBackgroundMenu = function() {
				var scroll_start = 0;
				var startchange = $('#page_content');
				var offset = startchange.offset();
				if (startchange.length){
					$(document).scroll(function() {
						scroll_start = $(this).scrollTop();
						if(scroll_start > offset.top) {
							$(" .navbar-default").css('background-color', 'white');
							$(" #main_navigation").addClass( 'menu-fixed' );
						} else {
							$(' .navbar-default').css('background-color', 'rgba(255,255,255, .8)');
							$(" #main_navigation").removeClass( 'menu-fixed' );
						}
					});
				}
			};

			/**
			* Mobile menu colapse
			* @author Krzysztof Tomecki
			*/
			_initMobileMenu = function(){
				$('#toggle').click(function() {
					$('#overlay').addClass('open');
					$('body').addClass('menu-open');
				});

				$('.menu-overlay .close-overlay').click(function(){
					$('#overlay').removeClass('open');
					$('body').removeClass('menu-open');
				});

				$('.menu-overlay .menu-item').click(function(){
					$('#overlay').removeClass('open');
					$('body').removeClass('menu-open');
				});

				$('.menu-item-has-children .submenu-btn').click(function(){
					if( $(this).hasClass('close') ) {
						$('.menu-item-has-children .submenu-btn').removeClass('open').addClass('close');
						$('.menu-item-has-children .dropdown-menu').slideUp();
						$(this).parent().find('.dropdown-menu').addClass('open').slideDown();
						$(this).removeClass('close').addClass('open');

					} else {
						$(this).parent().find('.dropdown-menu').slideUp();
						$(this).removeClass('open').addClass('close');

					}
				});

			}
			;

			return {
				init: init,
				finalize: finalize
			};

		})();

	});
});

